import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import SectionContainer from '../components/SectionContainer';
import SectionHeader from '../components/SectionHeader';
import SEO from '../components/SEO';
import WhiteBox from '../components/WhiteBox';
import { rhythm } from '../utils/typography';

const propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.object,
  }).isRequired,
};

const BlogPage = ({ data, location }) => {
  const styles = {
    container: {
      maxWidth: '600px',
      margin: 'auto',
      position: 'relative',
    },
    rssLink: {
      display: 'block',
      position: 'absolute',
      right: 0,
      top: '-2rem',
      ' img': {
        width: '1.5rem',
      },
    },
    boxInner: {
      padding: rhythm(1),
    },
    blogTitleImage: {
      border: '1px solid #aaa',
      objectFit: 'cover',
      height: '160px',
      width: '100%',
      filter: 'brightness(95%) grayscale(30%)',
    },
    blogDetails: {
      marginTop: '0.6rem',
    },
    link: {
      display: 'block',
      textDecoration: 'none',
      marginBottom: '2rem',
    },
    title: {
      fontSize: '1.3rem',
      margin: 0,
      marginBottom: '1rem',
      textAlign: 'left',
    },
    summary: {
      color: '#262626',
      marginBottom: rhythm(2 / 3),
    },
    datetime: {
      color: '#999',
      display: 'block',
      fontSize: '0.8rem',
    },
  };

  const rssPath = '/rss.xml';

  return (
    <Layout location={location}>
      <SectionContainer colorNumber="1" isTop>
        <SectionHeader colorNumber="1" text="Blogs" />
        <div css={styles.container}>
          {/*<a href={rssPath} css={styles.rssLink}>*/}
          {/*  <img src={rssSvg} alt="rss" />*/}
          {/*</a>*/}
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Link
              to={`/blog${node.fields.slug}`}
              css={styles.link}
              key={node.frontmatter.title}
            >
              <WhiteBox hover>
                <article css={styles.boxInner}>
                  <img src={node.frontmatter.thumbnail} css={styles.blogTitleImage} alt="blog" />
                  <div css={styles.blogDetails}>
                    <h1 css={styles.title}>{node.frontmatter.title}</h1>
                    <div css={styles.summary}>{node.frontmatter.summary}</div>
                    <time css={styles.datetime} dateTime={node.frontmatter.date}>
                      {node.frontmatter.date.slice(0, 10)}
                    </time>
                  </div>
                </article>
              </WhiteBox>
            </Link>
          ))}
        </div>

        <SEO
          metaData={{
            title: 'My Blogs',
            description: 'My blogs on certain technical topics: AWS, Elemental, OBS',
          }}
        />

        <Helmet
          link={[
            {
              rel: 'alternate',
              type: 'application/atom+xml',
              href: rssPath,
              title: 'RSS for My Blog',
            },
          ]}
        />
      </SectionContainer>
    </Layout>
  );
};

BlogPage.propTypes = propTypes;

export default BlogPage;

export const query = graphql`
  query BlogPageQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            summary
            date
            thumbnail
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
